import '../../node_modules/bootstrap/dist/js/bootstrap.min.js';
import '../../components/navigation/navigation.js'
import '../../components/footer/footer.js'
import '../../components/accessibility-bar/accessibility-bar.js'
import '../../components/offer-section/offer-section.js'
import '../../components/cta-section/cta-section.js'
import '../../components/cookie-banner/cookie-banner.js';
import './pageFront.scss';
import AOS from 'aos';
import { tns } from "../../node_modules/tiny-slider/src/tiny-slider";
import '../../node_modules/aos/dist/aos.css';

AOS.init();

// tns({
//   container: '.review-slider',
//   controlsContainer: document.querySelector('.review-controls'),
//   items: 1,
//   controlsPosition: 'bottom',
//   autoplay: false,
//   startIndex: 1,
//   dots: false,
//   nav: false,
//   responsive: {
//     991: {
//       items: 3
//     }
//   }
// });
//
//
// tns({
//   container: '.partners-slider',
//   controlsContainer: document.querySelector('.partners-controls'),
//   controlsPosition: 'bottom',
//   autoplay: false,
//   startIndex: 1,
//   dots: false,
//   nav: false,
//   responsive: {
//     991: {
//       items: 5
//     }
//   }
// });


try {
  const btnAnim = document.querySelector('.header-section .btn-anim');
  const headerCanvas = document.querySelector('.header-section .parallax-box');
  const btnArrowDown = document.querySelector('.header-section .btn-arrow-down');

  btnAnim.addEventListener('click', () => {
    headerCanvas.classList.toggle('disabled');
    btnArrowDown.classList.toggle('disabled');
    btnAnim.innerHTML = headerCanvas.classList.contains('disabled') ? 'Włącz animację' : 'Wyłącz animację';
  })

} catch (e) {
  console.log(e);
}
